import React, { Component } from 'react';
import styles from './error-boundary.module.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, count: '/' };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    // componentDidCatch(error, errorInfo) {
    //     console.error('Error caught by ErrorBoundary:', error, errorInfo);
    // }

    componentDidMount() {
        this.functionName();
    }

    functionName() {
        const location = document.location.pathname;
        if(location.split('/')[1] === 'admin'){
            this.setState({
                count: '/admin/login'
            });
        } else {
            this.setState({
                count: '/'
            });
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any fallback UI
            return (<div className={styles["error-boundary"]}>
                <span className="fa fa-exclamation"></span>
                <h2>Oops! Something went wrong.</h2>
                <p>We apologize for the inconvenience. Please try refreshing the page or come back later.</p>
                <a href={this.state.count}><button className="btn primary-button">Dashboard</button></a>
            </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;