import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { StepContext } from './Components/StepContext';
import App from './App';
    
ReactDOM.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
        <ErrorBoundary>
            <StepContext>
                <App />
            </StepContext>
        </ErrorBoundary>
    </GoogleOAuthProvider>
, document.getElementById('root'));